import type { SignupFormProps } from './signup-form';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  useFeatureFlagEnabled,
  useFeatureFlagVariantKey,
} from 'posthog-js/react';
import {
  Form,
  Button,
  Paragraph,
  TextFieldControlled as Input,
  ToggleFieldControlled as Toggle,
  SelectFieldMinimal,
  FieldWrap,
} from '@leafwell/components';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { clientId } from 'lib/tracking/gtag';
import { setStateCookie } from 'lib/cookies/state-cookie';
import { useEventsTracker } from 'contexts/eventsTracker';
import { useBrazeContext } from 'contexts/braze-context';
import { StatesListItem, useStatesList } from 'hooks/useStatesList';
import { useMedicalCardSignup } from 'hooks/useMedicalCardSignup';
import { useMedicalCardParams } from 'hooks/useMedicalCardParams';
import SezzleModal from './sezzle-modal';
import { getTemporaryUtmReferrerQuery } from './marketing-form';
import { useRouter } from 'next/router';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    before?: string;
  }
}

type SignupFormTelehealthProps = Pick<
  SignupFormProps,
  | 'redirectOnStateChange'
  | 'readOnlyStateField'
  | 'utmParams'
  | 'isRenewal'
  | 'inputEmail'
> & {
  src?: string;
  subTitle?: string;
  formSubmitText?: string;
  stateCode?: string;
  className?: string;
  isCardForm?: boolean;
  hasReferral?: boolean;
  isRenewal?: boolean;
  inputEmail?: string;
  totalMedicalCardPrice?: string;
  hasCustomStateCode?: boolean;
  pauseAppointments?: boolean;
  hasValueMessageExperiment?: boolean;
  valueMessageExperimentFormLabel?: string;
  placeAdPixel?: boolean;
};

function FormSubmitMessage() {
  return (
    <div className="mb-1 leading-8 text-center">
      <p>Thank you for choosing Leafwell.</p>
      <p>Someone on our team will be in touch with you soon.</p>
    </div>
  );
}

const SignupFormDefault: React.FC<SignupFormTelehealthProps> = memo(
  ({
    src,
    utmParams,
    stateCode: initialStateCode,
    isCardForm,
    redirectOnStateChange = true,
    isRenewal,
    readOnlyStateField = false,
    inputEmail,
    subTitle,
    formSubmitText,
    totalMedicalCardPrice,
    hasCustomStateCode = false,
    pauseAppointments = false,
    hasReferral = false,
    hasValueMessageExperiment = false,
    valueMessageExperimentFormLabel = null,
    placeAdPixel = false,
  }) => {
    const [selectedState, setSelectedState] =
      useState<string>(initialStateCode);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const statesList = useStatesList(isRenewal);
    const { trackEvent } = useEventsTracker();
    const medicalCardParams = useMedicalCardParams({
      initialUrl: src,
      params: utmParams,
    });
    const [isReferral, setReferral] = useState<boolean>(
      medicalCardParams?.email_friend?.length > 0,
    );
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { createSignup, updateSignup } = useMedicalCardSignup();
    const { translations, cardPage, statePages, renewalPages } =
      useWordPressContext();
    const {
      saveUser: saveBrazeUser,
      changeUser,
      requestImmediateDataFlush,
    } = useBrazeContext();

    const [discountCode, setDiscountCode] = useState<string>(null);
    const { query } = useRouter();

    // show sezzle modal experiment
    const showSezzleModalEnabled = useFeatureFlagEnabled(
      'mc-onboarding-sezzle-v2',
    );
    const showSezzleModalVariant = useFeatureFlagVariantKey(
      'mc-onboarding-sezzle-v2',
    );

    // remove cellphone field experiment
    const removeCellphoneFieldEnabled = useFeatureFlagEnabled(
      'remove-cellphone-field',
    );
    const removeCellphoneFieldVariant = useFeatureFlagVariantKey(
      'remove-cellphone-field',
    );

    function setFormErrors(setError, errors) {
      Object.entries(errors).forEach(error => {
        const [name, errorList] = error;
        let errorName = `patient_registry_${name}`;

        if (name === 'organization') {
          errorName = 'stateCode';
        }

        setError(errorName, {
          type: 'server',
          message: errorList[0],
        });
      });
    }

    useEffect(() => {
      if (query.discount_code && query.discount_code != '') {
        setDiscountCode(query.discount_code.toString().toUpperCase());
      }
    }, [query]);

    const handleSelectState = (stateCode?: string) => {
      const hasStateCode = stateCode && stateCode !== '';
      const url = hasStateCode
        ? isRenewal
          ? renewalPages.find(({ code }) => code === stateCode)?.uri
          : statePages.find(({ code }) => code === stateCode)?.uri
        : cardPage?.uri;

      if (hasStateCode) {
        setStateCookie(stateCode);
      }

      if (url && redirectOnStateChange) {
        window.location.assign(`${url}${getTemporaryUtmReferrerQuery()}`);
      }

      // update selected field if a state change - in the form dropdown - is not supposed to trigge
      // the redirect to its medical card page (block content, for example)
      if (!redirectOnStateChange) {
        setSelectedState(stateCode);
      }
    };

    const onSubmit = async (
      {
        stateCode,
        patient_registry_email,
        patient_registry_phone,
        patient_registry_email_friend,
        patient_registry_accepted_terms_of_service,
      },
      { clearErrors, setError },
    ) => {
      if (pauseAppointments) {
        trackEvent({
          event: 'form_submit_medicalcard_down',
          location: isCardForm
            ? hasCustomStateCode
              ? stateCode
              : translations[stateCode.toUpperCase()]
            : 'cannacon',
        });
        setFormSubmitted(true);
      } else if (patient_registry_accepted_terms_of_service) {
        setIsLoading(true);

        // Clear all errors before submit (specially the ones that might've come from the server)
        clearErrors();

        if (!stateCode) {
          setError('stateCode', {
            type: 'client',
            message: 'Invalid state',
          });
          setIsLoading(false);

          return;
        }

        const params = {
          ...medicalCardParams,
          state_code: stateCode,
          email: patient_registry_email,
          phone: patient_registry_phone,
          email_friend: patient_registry_email_friend,
          discount_code: discountCode ?? '',
        };
        const createRes = await createSignup(params);

        if (createRes.error) {
          setFormErrors(setError, createRes.errors);
          setIsLoading(false);

          return;
        }

        const updateRes = await updateSignup({
          ...params,
          uuid: createRes.data.uuid,
          accepted_terms_of_service: true,
        });
        const { uuid, redirect_url, error, errors } = updateRes;

        if (error || errors) {
          if (updateRes.data?.redirect_url) {
            window.location.assign(updateRes.data.redirect_url);
          } else {
            setFormErrors(setError, errors);
            setIsLoading(false);
          }

          return;
        }

        // Identify the user with MC uuid - https://js.appboycdn.com/web-sdk/5.1/doc/modules/braze.html#changeuser
        changeUser(createRes.data.uuid);

        // save conversion on Google Ads to leafwell.us
        if (
          process.env.NODE_ENV === 'production' &&
          window?.location?.hostname === 'leafwell.us'
        ) {
          // @ts-ignore: function is being initialized on _app.tsx
          gtag_report_conversion();
        }

        const referrerData = medicalCardParams?.utm_referrer
          ? { page_referrer: medicalCardParams.utm_referrer }
          : {};
        const discountCodeData = discountCode
          ? { discount_code: discountCode }
          : {};

        trackEvent({
          event: 'generate_lead',
          location: isCardForm
            ? hasCustomStateCode
              ? stateCode
              : translations[stateCode.toUpperCase()]
            : 'cannacon',
          medical_card_id: uuid,
          email: patient_registry_email,
          phone: patient_registry_phone,
          client_id: clientId(),
          ...referrerData,
          ...discountCodeData,
        });

        // immediately flushes queued data events to braze servers
        // https://js.appboycdn.com/web-sdk/5.1/doc/modules/braze.html#requestimmediatedataflush
        requestImmediateDataFlush();

        saveBrazeUser({
          email: patient_registry_email,
          phone: patient_registry_phone,
          customAttributes: {
            state: isCardForm
              ? hasCustomStateCode
                ? stateCode
                : translations[stateCode.toUpperCase()]
              : 'cannacon',
          },
        });

        if (
          placeAdPixel &&
          process.env.NODE_ENV === 'production' &&
          window?.location?.hostname !== 'dev.leafwell.com'
        ) {
          const jointcommerceScript = document.createElement('script');
          jointcommerceScript.id = 'jointcommerce-conversion-pixel';
          jointcommerceScript.innerHTML = `var pixelLoaderScript=document.createElement("script");
            pixelLoaderScript.src="https://app.jointcommerce.com/api/pixel-loader.js?pixel_id=612&event_type=conversion&client_user_id=${uuid}";
            document.head.appendChild(pixelLoaderScript);`;

          document.body.appendChild(jointcommerceScript);
        }

        if (redirect_url && !placeAdPixel) {
          setIsLoading(false);
          window.location.assign(redirect_url);
        } else {
          //we need to delay the redirect to the MC domain to assure the ad pixel is fired
          setTimeout(() => {
            setIsLoading(false);
            window.location.assign(redirect_url);
          }, 1_500);
        }
      }
    };

    const selectedStateObject: StatesListItem = useMemo(
      () => statesList.find(item => item.value === initialStateCode),
      [statesList, initialStateCode],
    );

    return (
      <Form
        className="md:gap-x-3 gap-y-4"
        mode="onSubmit"
        defaultValues={{
          stateCode: selectedState,
          patient_registry_email: inputEmail
            ? inputEmail
            : medicalCardParams?.email ?? '',
          patient_registry_phone: medicalCardParams?.phone ?? '',
          patient_registry_email_friend: medicalCardParams?.email_friend ?? '',
        }}
        onSubmit={onSubmit}
      >
        {hasReferral ? (
          <div className="flex justify-between bg-white border rounded-lg border-gray-400 w-full mb-2 p-0.5">
            <div
              className={[
                'w-1/2 flex justify-center items-center py-2 font-medium cursor-pointer',
                isReferral
                  ? 'bg-white'
                  : 'bg-lilac-500 border boder-lilac-500 rounded-lg text-white',
              ].join(' ')}
              onClick={() => setReferral(false)}
            >
              Just for you
            </div>
            <div
              className={[
                'relative w-1/2 flex justify-center items-center py-2 border rounded-lg font-medium cursor-pointer',
                isReferral
                  ? 'bg-lilac-500 boder-lilac-500 text-white'
                  : 'bg-white border-white',
              ].join(' ')}
              onClick={() => setReferral(true)}
            >
              For you + a friend
              <span className="absolute -top-4 -right-4 py-0.5 px-1 rounded text-sm text-black bg-lilac-300">
                10% off
              </span>
            </div>
          </div>
        ) : null}
        {subTitle && (
          <Paragraph className="text-xl text-center mb-2">{subTitle}</Paragraph>
        )}
        {hasValueMessageExperiment &&
        valueMessageExperimentFormLabel !== null ? (
          <p
            className="text-lg text-center"
            dangerouslySetInnerHTML={{
              __html: valueMessageExperimentFormLabel,
            }}
          />
        ) : null}
        {showSezzleModalEnabled && showSezzleModalVariant !== 'control' ? (
          <SezzleModal totalMedicalCardPrice={totalMedicalCardPrice} />
        ) : null}
        {discountCode ? (
          <div className="bg-green-50 border border-green-200 rounded-xl text-center text-xs p-2">
            <strong>{discountCode}</strong> Discount Applied!
          </div>
        ) : null}
        {isCardForm && !hasCustomStateCode && (
          <div className="grid gap-y-2 js-form-field relative">
            <p className="font-medium">{`You're applying for`}</p>
            <FieldWrap name="stateCode">
              <SelectFieldMinimal
                aria-label="Select your state"
                defaultValue={selectedStateObject}
                placeholder={translations['Select State']}
                options={statesList}
                disabled={readOnlyStateField}
                onSelect={target => handleSelectState(target.value)}
              />
            </FieldWrap>
          </div>
        )}
        {formSubmitted ? (
          <FormSubmitMessage />
        ) : (
          <>
            <div className="grid gap-y-2 js-form-field relative">
              <p className="font-medium">
                Email <sup>*</sup>
              </p>
              <Input
                className="rounded-lg"
                name="patient_registry_email"
                placeholder="Email *"
                type="email"
                validations={['required', 'email']}
              />
            </div>
            {removeCellphoneFieldEnabled &&
            removeCellphoneFieldVariant === 'control' ? (
              <div className="grid gap-y-2 js-form-field relative">
                <p className="font-medium">Cell Phone</p>
                <Input
                  className="rounded-lg"
                  name="patient_registry_phone"
                  type="tel"
                  placeholder="Cell Phone (optional)"
                />
                <p className="text-xs">
                  {
                    translations[
                      "Please add an international code if your number isn't a US number"
                    ]
                  }
                </p>
              </div>
            ) : null}
            {isReferral ? (
              <div className="grid gap-y-2 js-form-field relative">
                <p className="font-medium">
                  Friend's Email <sup>*</sup>
                </p>
                <Input
                  className="rounded-lg"
                  name="patient_registry_email_friend"
                  placeholder="Friend's Email *"
                  type="email"
                  validations={['required', 'email']}
                />
              </div>
            ) : null}
            <div className="grid gap-y-2 js-form-field relative">
              <Toggle
                className="border-gray-300"
                type="checkbox"
                name="patient_registry_accepted_terms_of_service"
                validations={['required']}
                options={[
                  {
                    label: (
                      <p className="text-sm">
                        {translations['I have read and accept the']}{' '}
                        <a
                          target="_blank"
                          href="https://leafwell.com/terms-of-use"
                        >
                          {translations['terms of service']}
                        </a>{' '}
                        {translations['and']}{' '}
                        <a
                          target="_blank"
                          href="https://leafwell.com/privacy-policy"
                        >
                          {translations['privacy policy']}
                        </a>
                      </p>
                    ),
                    value: '1',
                  },
                ]}
              />
            </div>
            <div className="c-form__actions mt-2">
              <Button
                type="submit"
                label={formSubmitText}
                variant="secondary"
                name="commit"
                id="submitt_embeded_step_1"
                className="w-full rounded-lg"
                loading={isLoading}
                data-disable-with={formSubmitText}
              />
            </div>
          </>
        )}
      </Form>
    );
  },
);

const SignupFormTelehealth: React.FC<SignupFormTelehealthProps> = props => {
  return (
    <div className="relative w-full lg:self-end">
      <SignupFormDefault {...{ ...props, isCardForm: props?.isCardForm }} />
    </div>
  );
};

export default SignupFormTelehealth;
